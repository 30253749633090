import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../SideBar';
import axios from 'axios';

export default function Edit() {
    const url = process.env.REACT_APP_BASE_URL;

    const [message, setMessage] = useState('');
    const [data, setData] = useState(null);

    const { id } = useParams();

    async function getData() {
        try {
            const response = await axios.post(`${url}/server/user/view.php`, {
                user_id: id
            });
            const responseData = response.data;
            setMessage(responseData.message);
            setData(responseData.data);

        } catch (error) {
            setMessage('Sorry Failed Edit Your Record');
        }
    }

    useEffect(() => {
        getData();
    });

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Edit User </p>
                            <b className='text-light p-1 rounded-3 my-1'>{message ? message : ''}</b>
                            <Link to={'/dashboard/Users'}><i className='btn btn-light p-1 my-1'>Back</i></Link>
                        </div>
                        <div>
                            {data && (
                                <>
                                    <h1>Name: {data[0].name}</h1>
                                    <h1>Email: {data[0].email}</h1>
                                    <h1>Mobile Number: {data[0].mobileNumber}</h1>
                                    <h1>Status: {data[0].status}</h1>
                                    <h1>Role: {data[0].role}</h1>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
