import React from 'react';

const url = process.env.REACT_APP_BASE_URL;

const Form = ({ onSubmit, onChange, formData, mode }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(event); // Call the onSubmit function passed from the parent component
    };

    const handleChange = (event) => {
        // const { name, value } = event.target;
        onChange(event); // Call the onChange function passed from the parent component
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                        <div className="form-group">
                            <label>Title:</label>
                            <input className="form-control" type="text" name="title" value={formData.title} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Sub Title:</label>
                            <input className="form-control" type="text" name="sub_title" value={formData.sub_title} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>URL:</label>
                            <input className="form-control" type="text" name="url" value={formData.url} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="image">Image:</label>
                            <input type="file" name="image" onChange={handleChange}placeholder="Image"/>
                            {formData.image && (
                                <img src={`${url}/server/${formData.image}`} style={{ width: '30%' }} alt={formData.title} className="img-fluid" />
                            )}
                        </div>
                        <div className="form-group">
                            <label>Description:</label>
                            <textarea className="form-control" name="description" value={formData.description} onChange={handleChange}></textarea>
                        </div>
                        <div className='my-2'>
                            {mode === 'add' && <button className="btn btn-primary" type="submit">Add</button>}
                            {mode === 'edit' && <button className="btn btn-primary" type="submit">Edit</button>}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Form;
