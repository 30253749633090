import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const [message, setMessage] = useState([]);
    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/user/index.php`);
            const responseData = response.data;
            if (responseData.status !== false) {
                setData(responseData.data);
            }else{
                setMessage(responseData.message)
            }
        } catch (error) {
            toast.error('Error fetching data:', error);
        }
    };

    async function deleteCategory(id,name) {
        try {
            const response = await axios.post(`${url}/server/user/delete.php`, {
                user_id: id
            });
            const responseData = response.data;
            setMessage(`${name} - ${responseData.message}`);
            toast.success(`${name} - ${responseData.message}`);
            fetchData()
        } catch (error) {
            setMessage(`Sorry, ${name} could not be deleted at this time.`);
            toast.error('Can\'t Delete This Time', error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [data.length]);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Users </p>
                            <b className='text-light p-1 rounded-3 my-1'>{message}</b>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email ID</th>
                                        <th>Status</th>
                                        <th>Role</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(data) && data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}-{item.username}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.email}</td>
                                            <td>{item.status}</td>
                                            <td>{item.role}</td>
                                            <td>
                                                <Link to={`Edit/${item.id}`}><i className='btn btn-success fa fa-edit'></i></Link> &nbsp;
                                                <Link to="#" onClick={() => deleteCategory(item.id,item.name)}><i className='btn btn-danger fa fa-trash-alt'></i></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}