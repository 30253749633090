import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../Components/utility/Loader'

export default function Project() {
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([])
  async function getData() {
    try {
      const response = await axios.post(`${url}/server/project/index.php`);
      const responseData = response.data;
      if (responseData !== false) {
        setData(responseData.data)
      } else {
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      <div className='container-fluid project py-5 mb-5'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='text-primary'>Our Project</h5>
            <h1>Our Recently Completed Projects</h1>
          </div>
          <div className='row g-5 text-center'>
            {data.length ? (
              data.map((item, index) => (
                <div
                  className='col-md-6 col-lg-4 wow fadeIn'
                  data-wow-delay='.3s'
                  key={index}
                >
                  <div className='project-item'>
                    <div className='project-img'>
                      <img
                        className='img-fluid max-w-4xl rounded'
                        src={item.image ? `${url}/server/assets/project/${item.image}` : `${url}/server/assets/noImages.jpeg`}
                        alt={item.dealTitle}
                        style={{ width: '300px', height: '200px' }}
                      />
                      <div className='project-content'>
                        <Link to='#' className='text-center'>
                          <h4 className='text-secondary'>{item.title}</h4>
                          <p className='m-0 text-white'>{item.sub_title}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>

        </div>
      </div>
    </>
  )
}
