import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './views/Navbar'
import Footer from './views/Footer'
import Index from './views/Index'
import Carrier from './views/Carrier'
import About from './views/About'
import Contacts from './views/Contacts'
import ShoppingTime from './views/ShoppingPoint'
import CreditCard from './views/CreditCard'
import Qrcode from './views/Qrcode'
import PlantProduct from './views/PlantProduct'
import Error from './views/Error'
import TopBar from './views/TopBar'
import Pancard from './views/Pancard'
import Services from './views/Deals'
import Project from './views/Project'
import Blog from './views/Blog'
import Terms from './views/Terms'
import Loans from './views/Loans'
import Insurance from './views/Insurance'
import PrivacyPolicy from './views/PrivacyPolicy'
import Helps from './views/Helps'
import Faqs from './views/Faqs'
import Login from './views/Login'
import Signup from './views/Signup'
import OTP from './views/Otp'
import Teams from './views/Teams'
import Testimonial from './views/Testimonials'
import Dashboard from './views/dashboard/Index'
import PagesList from './views/dashboard/Pageroute/Index'
import PageAdd from './views/dashboard/Pageroute/Add'
import EditPage from './views/dashboard/Pageroute/Edit'
import Profile from './views/dashboard/Profile'
import Brand from './views/dashboard/Brand/Index'
import Category from './views/dashboard/Category/Index'
import Users from './views/dashboard/Users/Index'
import Productlist from './views/dashboard/Product/Index'
import ContactDash from './views/dashboard/Contact/Index'
import RealState from './views/dashboard/Realstate/Index'
import PanCard from './views/dashboard/pancard/Index'
import DashInsurance from './views/dashboard/Insurance/Index'
import DashLoans from './views/dashboard/Loans/Index'
import AddLoan from './views/dashboard/Loans/Add'
import EditLoan from './views/dashboard/Loans/Edit'
import Testimonials from './views/dashboard/Testimonials/Index'
import NewsLetters from './views/dashboard/Newslatter/Index'
import Dslider from './views/dashboard/Slider/Index'
import AddCategory from './views/dashboard/Category/Add'
import EditCategory from './views/dashboard/Category/Edit'
import DealsServices from './views/dashboard/deals/Index'
import Leads from './views/dashboard/Leads/Index'
import Svisitor from './views/dashboard/Svisitor/Index'
import Add from './views/dashboard/Slider/Add'
import Edit from './views/dashboard/Slider/Edit'
import EditUser from './views/dashboard/Users/Edit'

export default function App () {
  
  return (
    <>
      <BrowserRouter>
        <TopBar />
        <Navbar />
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/about' element={<About />} />
          <Route path='/services-deals' element={<Services />} />
          <Route path='/project' element={<Project />} />
          <Route path='/contact-us' element={<Contacts />} />
          <Route path='/carrier' element={<Carrier />} />
          <Route path='/shopping-point' element={<ShoppingTime />} />
          <Route path='/credit-card' element={<CreditCard />} />
          <Route path='/qr-code' element={<Qrcode />} />
          <Route path='/pan-card' element={<Pancard />} />
          <Route path='/plantProduct' element={<PlantProduct />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/loan' element={<Loans />} />
          <Route path='/team' element={<Teams />} />
          <Route path='/all-insurance' element={<Insurance />} />
          <Route path='/testimonial' element={<Testimonial />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/helps' element={<Helps />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/OTP' element={<OTP/>} />
          {/* Dashboard Page */}
          <Route path='/dashboard' element={localStorage.getItem('login') ? <Dashboard /> : <Login />}/>
          <Route path='/dashboard/Page-List' element={localStorage.getItem('login') ? <PagesList /> : <Login />}/>
          <Route path='/dashboard/Add-Page' element={localStorage.getItem('login') ? <PageAdd /> : <Login />}/>
          <Route path='/dashboard/Edit-Page/:id' element={localStorage.getItem('login')?<EditPage/>:<Login/>}/>
          <Route path='/dashboard/users'element={localStorage.getItem('login') ? <Users /> : <Login />}/>
          <Route path='/dashboard/leads'element={localStorage.getItem('login') ? <Leads /> : <Login />}/>
          <Route path='/dashboard/deals'element={localStorage.getItem('login') ? <DealsServices /> : <Login />}/>
          <Route path={`/dashboard/users/Edit/:id`} element={localStorage.getItem('login') ? <EditUser /> : <Login />} /> 
          <Route path='/dashboard/profile' element={localStorage.getItem('login') ? <Profile /> : <Login />} /> 
          <Route path='/dashboard/brand' element={localStorage.getItem('login') ? <Brand /> : <Login />} /> 
          <Route path='/dashboard/slider' element={localStorage.getItem('login') ? <Dslider /> : <Login />} /> 
          <Route path='/dashboard/slider/add' element={localStorage.getItem('login') ? <Add /> : <Login />} /> 
          <Route path='/dashboard/slider/edit' element={localStorage.getItem('login') ? <Edit /> : <Login />} /> 
          <Route path='/dashboard/product' element={   localStorage.getItem('login') ? <Productlist /> : <Login /> } /> 
          <Route path='/dashboard/contact-us' element={localStorage.getItem('login') ? <ContactDash /> : <Login />} /> 
          <Route path='/dashboard/real-state' element={localStorage.getItem('login') ? <RealState /> : <Login />} /> 
          <Route path={`/dashboard/pan-card`} element={   localStorage.getItem('login') ? <PanCard /> : <Login /> } /> 
          <Route path={`/dashboard/Insurances-list`} element={   localStorage.getItem('login') ? <DashInsurance /> : <Login /> } /> 
          <Route path={`/dashboard/Loans-List`} element={   localStorage.getItem('login') ? <DashLoans /> : <Login /> } /> 
          <Route path={`/dashboard/Add-Loan`} element={   localStorage.getItem('login') ? <AddLoan /> : <Login /> } /> 
          <Route path={`/dashboard/Edit-Loan/:id`} element={   localStorage.getItem('login') ? <EditLoan /> : <Login /> } /> 
          <Route path='/dashboard/testimonials' element={   localStorage.getItem('login') ? <Testimonials /> : <Login /> } /> 
          <Route path='/dashboard/newsletters' element={   localStorage.getItem('login') ? <NewsLetters /> : <Login /> } /> 
          <Route path='/dashboard/check-out' element={localStorage.getItem('login') ? <Profile /> : <Login />} /> 
          <Route path='/dashboard/Category' element={localStorage.getItem('login') ? <Category /> : <Login />} /> 
          <Route path='/dashboard/category/add-categroy' element={   localStorage.getItem('login') ? <AddCategory /> : <Login /> } /> 
          <Route path={`/dashboard/category/edit-category/:id`} element={   localStorage.getItem('login') ? <EditCategory /> : <Login /> } /> 
          <Route path={`/dashboard/Svisitor/`} element={localStorage.getItem('login')?<Svisitor/>:<login/>}/>
          <Route path='/dashboard/*' element={ localStorage.getItem('login') ? ( <Error /> ) : (<Navigate to='/error' />) }/>
          <Route path='/error' element={<Error />} />
          <Route path='/*' element={<Error />} />
        </Routes>
        <ToastContainer />
        <Footer />
      </BrowserRouter>
    </>
  )
}
