import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import axios from 'axios'
import Loader from '../Components/utility/Loader'

export default function Teams() {
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([])

  async function getData() {
    try {
      const response = await axios.post(`${url}/server/teams/index.php`)
      const responseData = response.data
      if(responseData.status!==false){
        setData(responseData.data)
      }else{
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      <div className='container-fluid py-5 mb-5 team'>
        <div className='container text-center'>
          <div className='text-center mx-auto pb-5 wow fadeIn' data-wow-delay='.3s' style={{ maxWidth: '600px' }}>
            <h5 className='text-primary'>Our Team</h5>
            <h1>Meet Our Expert Team</h1>
          </div>
          <OwlCarousel className='owl-theme team-carousel wow fadeIn' data-wow-delay='.5s' loop margin={10} nav >
            {data.length ? (
              data.map((item, index) => (
                <div className='item rounded team-item' key={index}>
                  <div className='team-content'>
                    <div className='team-img-icon'>
                      <div className='team-img rounded-circle'>
                        <img src={`${url}/server/assets/teams/${item.image}`} className='img-fluid w-100 rounded-circle' alt={item.name} />
                      </div>
                      <div className='team-name text-center py-3'>
                        <h4>{item.name}</h4>
                        <p className='m-0'>{item.designation}</p>
                      </div>
                      <div className='team-icon d-flex justify-content-center pb-4'>
                        <Link className='btn btn-square btn-secondary text-white rounded-circle m-1' to='/'> <i className='fab fa-facebook-f'></i> </Link>
                        <Link className='btn btn-square btn-secondary text-white rounded-circle m-1' to='/'> <i className='fab fa-twitter'></i></Link>
                        <Link className='btn btn-square btn-secondary text-white rounded-circle m-1' to='/'> <i className='fab fa-instagram'></i></Link>
                        <Link className='btn btn-square btn-secondary text-white rounded-circle m-1' to='/'> <i className='fab fa-linkedin-in'></i> </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loader/>
            )}
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}
