import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoanFrm from './Loanfrm';
import { Link } from 'react-router-dom';

export default function Loans() {
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  async function getData() {
    try {
      const response = await axios.post(`${url}/server/loans/index.php`);
      const responseData = response.data;
      if (responseData.status !== false) {
        setData(responseData.data);
      } else {
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleButtonClick = async (item) => {
    setSelectedItem(item);
  };
  if (!data.length>0) {
    return <h3 className="text-center py-5">Loading...</h3>;
  }
  return (
    <>
      <div className="container">
      <h1 className='py-2 my-2 text-center'>Find Loans We Offer</h1>
      <div className="row">
        {data && data.map((item, index) => {
          const description = item.details ? JSON.parse(item.details) : {};
          return (
            <div className="col-md-4 mb-3" key={index}>
              <div className="card" style={{ width: '18rem' }}>
                <img src={`${url}/server/assets/loans/${item.image}`} className="card-img-top" alt={item.name.replace(' ', '')}/>
                <div className="card-body">
                  <h5 className="card-title">{item.name}</h5>
                  <hr />
                  <h6 className='text-center'>Interest Rate</h6>
                  <div className='d-flex justify-content-between'>
                  <p className="card-text"><b>p/a :</b> {description.interestratePA}</p>
                  <p className="card-text"><b>p/m :</b> {description.interestratePM}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link to={''} className="btn btn-primary">Details</Link>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loanModal" onClick={() => handleButtonClick(item)} >
                      Get Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>

      {/* Modal */}
      <div className="modal fade" id="loanModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loanModalLabel">Apply Now</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedItem && <LoanFrm lkey={selectedItem.name} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
