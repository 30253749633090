import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Components/utility/Loader';
import axios from 'axios';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton,TelegramShareButton, FacebookIcon, TwitterIcon, WhatsappIcon,TelegramIcon } from 'react-share';


export default function CreditCard() {
  const [data, setData] = useState([])
  const url = process.env.REACT_APP_BASE_URL;

  async function getData() {
    try {
      const response = await axios.post(`${url}/server/creditcard/index.php`);
      const responseData = response.data
      if (responseData.status !== false) {
        setData(responseData.data);
      } else {
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])

  if (!data.length>0) {
    return <h3 className="text-center py-5">Loading...</h3>;
  }
  
  return (
    <>
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h2 className="text-primary">Card Center</h2>
            <h5>Latest Credit Card Details</h5>
          </div>
          <div className="row g-5 justify-content-center">
            {
              data.length ? (
                data.map((item, index) => (
                  <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s" key={index}>
                    <div className="blog-item position-relative bg-light rounded">
                      <img src={item.image ? `${url}/server/assets/creditcard/${item.image}` : `${url}/server/assets/noImages.jpeg`} className="img-fluid w-100 rounded-top" alt={item.title} />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{ top: '-28px', right: '20px' }}>{item.title}</span>
                      <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{ marginTop: '-75px' }}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                          <Link to="#" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                          <div className="blog-icon-1">
                            <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                          </div>
                          <div className="blog-icon-2">
                            <FacebookShareButton url={url} title={item.title} separator=":" quote={`${item.title}`}  hashtag={`${item.tilte}`} className="btn me-1">
                              <FacebookIcon size={29} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={url} title={item.title} separator=":" quote={`${item.title}`} hashtag={`${item.tilte}`} className="btn me-1">
                              <TwitterIcon size={29} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={url} title={item.title} separator=":" quote={`${item.title}`} hashtag={`${item.tilte}`} className="btn me-1">
                              <WhatsappIcon size={29} round />
                            </WhatsappShareButton>
                            <TelegramShareButton url={url} title={item.title} separator=":" quote={`${item.title}`} hashtag={`${item.tilte}`} className="btn me-1">
                              <TelegramIcon size={29} round />
                            </TelegramShareButton>
                          </div>
                        </div>
                      </div>
                      <div className="blog-content text-center position-relative px-3" style={{ marginTop: '20px' }}>
                        <h5 className="">{item.name}</h5>
                        <span className="text-secondary">{item.date}</span>
                        <p className="py-2">{item.description}</p>
                      </div>
                      <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom">
                        <Link to="" className="text-white"><small><i className="fas fa-share me-2 text-secondary"></i>{item.share} Share</small></Link>
                        <Link to="" className="text-white"><small><i className="fa fa-shopping-cart me-2 text-secondary"></i>{item.comment} Buy Now</small></Link>
                      </div>
                    </div>
                  </div>
                ))
              ) :
                (
                  <Loader />
                )
            }
          </div>
        </div>
      </div>
    </>
  )
}
