import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


function ContactForm() {
    const url = process.env.REACT_APP_BASE_URL;

    const initialState = {
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
    };
    const [message, setMessage] = useState();
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !formData.mobile) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (formData.mobile.length !== 10) {
            toast.error('Mobile number must be 10 digits!');
            return;
        }
        try {
            const response = await axios.post(`${url}/server/contact/create.php`, formData);
            const responseData = response.data
            if (responseData.status !== false) {
                setFormData(initialState);
                setMessage(responseData.message)
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    setTimeout(()=>{setMessage('')},10000)    
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input type="text" name="name" placeholder="Full Name" className="form-control" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <input type="email" name="email" placeholder="Email Address" className="form-control" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <input type="text" name="mobile" maxLength={10} minLength={10} placeholder="Mobile Number" className="form-control" value={formData.mobile} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <input type="text" name="subject" placeholder="Subject" className="form-control" value={formData.subject} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <textarea name="message" rows="5" className="form-control" placeholder="Message..." value={formData.message} onChange={handleChange}></textarea>
                </div>
                <p className='text-center text-success'>{message}</p>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary btn-sm w-100">Submit</button>
                </div>
            </form>
        </>
    );
}

export default function Contacts() {
    return (
        <>
            <div className="container-fluid py-5 mb-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
                        <h5 className="text-primary">Get In Touch</h5>
                        <h1 className="mb-3">Contact for any query</h1>
                        <p className="mb-2">The contact form is Directly. Get a functional and working Sector a few minutes.<Link to="">Download Now</Link>.</p>
                    </div>
                    <div className="contact-detail position-relative p-5">
                        <div className="row g-5 mb-5 justify-content-center">
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="d-flex bg-light p-3 rounded">
                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                        <i className="fas fa-map-marker-alt text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="text-primary">Address</h4>
                                        <Link to="" className="h5">23 IT Park, New Delhi</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="d-flex bg-light p-3 rounded">
                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                        <i className="fa fa-phone text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="text-primary">Call Us</h4>
                                        <a className="h5" href="tel:+91 0000000000">+91 0000000000</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                                <div className="d-flex bg-light p-3 rounded">
                                    <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                        <i className="fa fa-envelope text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="text-primary">Email Us</h4>
                                        <Link className="h5" to="mailto:info@oracalpoint.com">info@oracalpoint.com</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="p-5 h-100 rounded contact-map">
                                    <h1>Map</h1>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
