import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    // for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex)
    const npage = Math.ceil(data.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/leads/index.php`);
            const responseData = response.data;
            if (responseData !== false) {
                setData(responseData.data);
            }
            setMessage(responseData.message)

        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    async function deleteLoan(emd, leadNm) {
        try {
            await axios.post(`${url}/server/leads/delete.php`, {
                loan_id: emd
            });
            toast.error(`${leadNm} Lead deleted successfully`);
            await fetchData();
        } catch (error) {
            console.log('Failed to delete Lead', error.response.data.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [data.length]);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Leads</p>
                            <Link to={'/dashboard/Add-Loan'} className='btn btn-success'><i className='fa fa-plus'></i></Link>
                        </div>
                        <div className="table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Lead Type</th>
                                        <th>Title</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>City</th>
                                        <th>Details</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records && records.length > 0 ? (
                                            records.map((item, index) => {
                                                const mobile = item.mobile ? JSON.parse(item.mobile) : {};
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.lead}</td>
                                                        <td>{item.name}</td>
                                                        <td>{mobile.No1}<br />{mobile.No2}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.address}</td>
                                                        <td>{''}</td>
                                                        <td>
                                                            <img src={`${url}/assets/leads/${item.files}`} alt={item.name.replace(' ', '')} width={100} className='p-1' />
                                                        </td>
                                                        <td className='d-flex'>
                                                            <Link to={`/dashboard/Edit-Loan/${item.id}`}><i className="btn btn-success fa fa-edit"></i></Link>&nbsp;
                                                            <Link onClick={() => deleteLoan(item.id, item.name)}><i className="btn btn-danger fa fa-trash-alt"></i></Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="9">
                                                    <h4 className="text-center">{message}</h4>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <nav>
                                <ul className='pagination'>
                                    {numbers.map((n, i) => (
                                        <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                                            <a href='#' className='page-link' onClick={() => setCurrentPage(n)}>{n}</a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}