import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Loader from '../Components/utility/Loader';

export default function Deals() {
  const [data, setData] = useState([]);
  const url = process.env.REACT_APP_BASE_URL;
  async function getApiData() {
    try {
      const response = await axios.post(`${url}/server/deals/index.php`);
      const responseData = response.data;
      if (responseData.status === true) {
        setData(responseData.data);
      } else {
        console.log(responseData.message);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  }

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div className="row g-5 services-inner">
            {
              data.length > 0 ?
                data.map((item) => (
                  <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s" key={item.id}>
                    <div className="services-item bg-light">
                      <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                          <img
                            src={item.image ? `${url}/server/assets/deal/${item.image}` : `${url}/server/assets/blankimg.png}`}
                            alt={item.title}
                            className="img-fluid mb-4"
                          />
                          <h4 className="mb-3">{item.title}</h4>
                          <p className="mb-4">{item.description}</p>
                          <div className="blog-btn d-flex justify-content-between position-relative px-3">
                            <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                              <Link to={`/${item.url}`} className="btn text-white">More</Link>
                            </div>
                            <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                              <div className="blog-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                              </div>
                              <div className="blog-icon-2">
                                <FacebookShareButton url={`${url}/${item.url}`} title={item.title} quote={item.title} media={item.image} hashtag={item.hashtag} className="btn me-1">
                                  <FacebookIcon size={25} className='my-1' round />
                                </FacebookShareButton>
                                <TwitterShareButton url={`${url}/${item.url}`} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                                  <TwitterIcon size={25} className='my-1' round />
                                </TwitterShareButton>
                                <WhatsappShareButton url={`${url}/${item.url}`} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                                  <WhatsappIcon size={25} className='my-1' round />
                                </WhatsappShareButton>
                                <TelegramShareButton url={`${url}/${item.url}`} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                                  <TelegramIcon size={25} className='my-1' round />
                                </TelegramShareButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : (
                  <Loader />
                )
            }
          </div>
        </div>
      </div>
    </>
  );
}
