import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Components/utility/Loader';

export default function Blog() {
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([])
  async function getData() {
    try {
      const response = await axios.post(`${url}/server/blog/index.php`);
      const responseData = response.data
      if (responseData.status !== false) {
        setData(responseData.data);
      } else {
        console.log(responseData.message)
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Our Blog</h5>
            <h1>Latest Blog & News</h1>
          </div>
          <div className="row g-5 justify-content-center">
            {
              data.length ? (
                data.map((item, index) => (
                  <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s" key={index}>
                    <div className="blog-item position-relative bg-light rounded">
                      <img src={item.bg_image} className="img-fluid w-100 rounded-top" alt="" />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{ top: '-28px', right: '20px' }}>{item.title}</span>
                      {/* <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{ marginTop: '-75px' }}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                          <Link to="#" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                          <div className="blog-icon-1">
                            <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                          </div>
                          <div className="blog-icon-2">
                            <Link to="#" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                            <Link to="#" className="btn me-1"><i className="fab fa-twitter text-white"></i></Link>
                            <Link to="#" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="blog-content text-center position-relative px-3" style={{ marginTop: '-25px' }}>
                        <img src={item.image} className="img-fluid rounded-circle border border-4 border-white mb-3" alt={item.title} style={{ width: '100px', height: '100px' }} />
                        <h5 className="">{item.name}</h5>
                        <span className="text-secondary">{item.date}</span>
                        <p className="py-2">{item.description}</p>
                      </div> */}
                      <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                        <Link to="" className="text-white"><small><i className="fas fa-share me-2 text-secondary"></i>{item.share} Share</small></Link>
                        <Link to="" className="text-white"><small><i className="fa fa-comments me-2 text-secondary"></i>{item.comment} Comments</small></Link>
                      </div>
                    </div>
                  </div>
                ))
              ) :
                (
                  <Loader />
                )
            }
          </div>

        </div>
      </div>
    </>
  );
}
