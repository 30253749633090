import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Insurance() {
  const url = process.env.REACT_APP_BASE_URL;
  const [message, setMessage] = useState('');
  const [insurances, setInsurances] = useState([]);
  const initialState = {
    Insurance: '',
    fname: '',
    mname: '',
    lname: '',
    mobile1: '',
    mobile2: '',
    email: '',
    city: '',
    address: '',
  };

  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${url}/server/insurances/create.php`, formData);
      const responseData = response.data;
      setMessage(responseData.message);
      if (responseData.status) {
        setFormData(initialState);
      }
    } catch (e) {
      console.log(e);
      setMessage('An error occurred.');
    }
  }

  const getInsurance = async () => {
    try {
      const response = await axios.post(`${url}/server/insurances/index.php`);
      const getInsData = response.data;
      if (getInsData.status !== false) {
        setInsurances(getInsData.data);
      } else {
        setMessage(getInsData.message);
      }
    } catch (e) {
      console.log(e);
      setMessage('An error occurred.');
    }
  };

  useEffect(() => {
    getInsurance();
  }, []);

  return (
    <>
      <div className="container">
        <h1 className="text-center">Insurance</h1>
        <h5 className="text-center">Just a few steps to get your insurance</h5>
        <div className="row">
          <div className="col-md-5 wow fadeIn" data-wow-delay=".3s">
            <div className="p-5 rounded img-fluid">
              <img src={`${url}/server/assets/insurance/Insurance.jpg`} className='w-100' alt="Insurance" />
            </div>
          </div>
          <div className="col-md-7">
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              method="POST"
            >
              <div className="mb-3 d-flex">
                <select className="form-control" name="Insurance" onChange={handleChange} value={formData.Insurance} required>
                  <option value="" disabled>
                    Please Select <b>*</b>
                  </option>
                  {insurances.map((item, index) => (
                    <option key={index} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 d-flex">
                <input
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  className="form-control"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="mname"
                  placeholder="Middle Name"
                  className="form-control"
                  value={formData.mname}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lname"
                  placeholder="Last Name"
                  className="form-control"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 d-flex">
                <input
                  type="tel"
                  name="mobile1"
                  maxLength={10}
                  minLength={10}
                  placeholder="Mobile Number"
                  className="form-control"
                  value={formData.mobile1}
                  onChange={handleChange}
                  required
                />
                <input
                  type="tel"
                  name="mobile2"
                  maxLength={10}
                  minLength={10}
                  placeholder="Alternate Mobile Number"
                  className="form-control"
                  value={formData.mobile2}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="form-control"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <textarea
                  name="address"
                  rows="2"
                  className="form-control"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                ></textarea>
              </div>
              
              <p className="text-center text-success">{message}</p>
              <div className="mb-3">
                <button type="submit" className="btn btn-primary btn-sm w-100">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
