import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel'
import Loader from '../Components/utility/Loader';

export default function Testimonials() {
  const url = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState([])

  async function getData() {
    try {
      const response = await axios.post(`${url}/server/testimonial/index.php`);
      const responseData = response.data
      if (responseData.status!==false) {
        setData(responseData.data);
      } else {
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      <div className="container-fluid testimonial py-5 mb-5">
        <div className="container text-center">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Our Testimonial</h5>
            <h1>Our Client Saying!</h1>
          </div>
          <OwlCarousel className="owl-carousel testimonial-carousel wow fadeIn" data-wow-delay=".5s">
            {data.length ? (
              data && data.map((item, index) => (
                <div className="testimonial-item border my-2 py-4 m-2" key={index}>
                  <div className="container d-flex align-items-center">
                    <div className="">
                    <img
                        className='img-fluid max-w-4xl rounded'
                        src={item.image ? `${url}/server/assets/testimonials/${item.image}` : `${url}/server/assets/noImages.jpeg`}
                        alt={item.dealTitle}
                        // style={{ width: '300px', height: '200px' }}
                      />
                    </div>
                    <div className="ms-4">
                      <h4 className="text-secondary">{item.name}</h4>
                      <p className="m-0 pb-3">{item.profession}</p>
                      <div className="d-flex pe-5">
                        <i className="fas fa-star me-1 text-primary"></i>
                        <i className="fas fa-star me-1 text-primary"></i>
                        <i className="fas fa-star me-1 text-primary"></i>
                        <i className="fas fa-star me-1 text-primary"></i>
                        <i className="fas fa-star me-1 text-primary"></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top m-2 mt-4 pt-3">
                    <p className="mb-0">{item.comment}</p>
                  </div>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </OwlCarousel>
        </div>
      </div>

    </>
  );
}
