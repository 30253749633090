import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const [message, setMessage] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/pages/index.php`);
            const responseData = response.data;
            if (responseData !== false) {
                setData(responseData.data);
            } else {
                setMessage(responseData.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const deletePages = async (id, title) => {
        try {
            const response = await axios.post(`${url}/server/pages/delete.php`, {
                page_id: id
            });
            const responseData = response.data;
            if (responseData.status === true) {
                toast.warning(`${title} ${responseData.message}`);
                await fetchData();
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            console.error('Failed to delete page:', error.message);
            toast.error('Failed to delete page');
        }
    };


    useEffect(() => {
        fetchData();
    }, [data.length]);

    return (
        <div className="container-fluid my-3">
            <div className="row">
                <div className="col-md-3">
                    <Sidebar />
                </div>
                <div className="col-md-9">
                    <div className='bg-primary d-flex justify-content-between'>
                        <p className='m-2 text-light'>Page's {message}</p>
                        <Link to={'/dashboard/Add-Page'} className='btn btn-success'><i className='fa fa-plus'></i></Link>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                    <tr className='text-center'>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>URL</th>
                                        <th>Image</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((item, index) => {
                                            let PageImg = {};
                                            try {
                                                PageImg = item.image ? JSON.parse(item.image) : {};
                                            } catch (e) {
                                                console.error('Invalid JSON:', e);
                                                PageImg = {};
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.url}</td>
                                                    <td className='text-center'>
                                                        <img src={PageImg.image ? `${url}/server/assets/pages/${PageImg.image}` : ''} width={100} alt={item.title} />
                                                    </td>

                                                    <td>{item.role}</td>
                                                    <td>{item.status}</td>
                                                    <td className='d-flex'>
                                                        <Link to={`/dashboard/Edit-Page/${item.id}`}><i className='btn btn-success fa fa-edit'></i></Link>&nbsp;
                                                        <button onClick={() => {
                                                            if (window.confirm('Do you want to delete records?')) {
                                                                deletePages(item.id, item.title);
                                                            }
                                                        }} className='btn btn-danger'><i className='fa fa-trash-alt'></i></button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="7" className="text-center">
                                                <h4>Loading...</h4>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
