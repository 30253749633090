import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/loans/index.php`);
            const responseData = response.data;
            if (responseData.status !== false) {
                setData(responseData.data);
            }
            setMessage(responseData.message)

        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    async function deleteLoan(emd, loanNm) {
        try {
            await axios.post(`${url}/server/loans/delete.php`, {
                loan_id: emd
            });
            toast.error(`${loanNm} Loan deleted successfully`);
            await fetchData();
        } catch (error) {
            console.log('Failed to delete loan', error.response.data.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Loans</p>
                            <Link to={'/dashboard/Add-Loan'} className='btn btn-success'><i className='fa fa-plus'></i></Link>
                        </div>
                        <div className="table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Details</th>
                                        <th>Image</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ? (
                                            data.map((item, index) => {
                                                const details = JSON.parse(item.details);
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{details.interestratePA} pa<br/>{details.interestratePM} pm</td>
                                                        <td>
                                                            <img src={`${url}/server/assets/loans/${item.image}`} alt={item.name.replace(' ', '')} width={100} className='p-1' />
                                                        </td>
                                                        <td>{item.status}</td>
                                                        <td className='d-flex'>
                                                            <Link to={`/dashboard/Edit-Loan/${item.id}`}><i className="btn btn-success fa fa-edit"></i></Link>&nbsp;
                                                            <Link onClick={() => deleteLoan(item.id, item.name)}><i className="btn btn-danger fa fa-trash-alt"></i></Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    ) : (
                                    <tr>
                                        <td colSpan="9">
                                            <h4 className="text-center">{message}</h4>
                                        </td>
                                    </tr>
                                    )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}