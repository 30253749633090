import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Navbar () {
  const url = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate()
  function logout () {
    localStorage.clear()
    navigate('/login')
  }
  return (
    <>
      <div className='container-fluid bg-primary'>
        <div className='container'>
          <nav className='navbar navbar-dark navbar-expand-lg py-0'>
            <Link to='/' className='navbar-brand'>
            <img src={`${url}/server/assets/logo.png`} className="img-fluid rounded-top" width={50} alt={'OracalPoint'} />
            </Link>
            <button
              type='button'
              className='navbar-toggler me-0'
              data-bs-toggle='collapse'
              data-bs-target='#navbarCollapse'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div
              className='collapse navbar-collapse bg-transparent'
              id='navbarCollapse'
            >
              <div className='navbar-nav ms-auto mx-xl-auto p-0'>
                <Link
                  to='/'
                  className='nav-item nav-link active text-secondary'
                >
                  {' '}
                  Home{' '}
                </Link>
                <Link to='/about' className='nav-item nav-link'>
                  {' '}
                  About{' '}
                </Link>
                <Link to='/carrier' className='nav-item nav-link'>
                  {' '}
                  Carrier{' '}
                </Link><Link to='/services-deals' className='nav-item nav-link'>
                  {' '}
                  Services{' '}
                </Link>
                <Link to='/project' className='nav-item nav-link'>
                  {' '}
                  Projects{' '}
                </Link>
                <div className='nav-item dropdown'>
                  <Link
                    to='#'
                    className='nav-link dropdown-toggle'
                    data-bs-toggle='dropdown'
                  >
                    More ... 
                  </Link>
                  <div className='dropdown-menu rounded'>
                    <Link to='/all-insurance' className='dropdown-item'>
                      {' '}
                      Insurance{' '}
                    </Link>
                    <Link to='/shopping-point' className='dropdown-item'>
                      {' '}
                      Shooping Point
                    </Link>  
                    <Link to='/loan' className='dropdown-item'>
                      {' '}
                      Loan's
                    </Link>                  
                    <Link to='/pan-card' className='dropdown-item'>
                      Pan Card
                    </Link>
                    <Link to='/qr-code' className='dropdown-item'>
                      {' '}
                      Qr Code
                    </Link>                    
                    <Link to='/credit-card' className='dropdown-item'>
                      {' '}
                      Credit Card
                    </Link>
                  </div>
                </div>
                <Link to='/contact-us' className='nav-item nav-link'>
                  {' '}
                  Contact{' '}
                </Link>
              </div>
            </div>
            <div className='d-xl-flex flex-shirink-0'>
              <div id='phone-tada'className='d-flex align-items-center justify-content-center me-4' >
                <Link
                  to='javascript()'
                  className='position-relative animated tada infinite'
                >
                  <i className='fa fa-phone-alt text-white fa-2x'></i>
                  <div
                    className='position-absolute'
                    style={{ top: '-7px', left: '20px' }}
                  >
                    <span>
                      <i className='fa fa-comment-dots text-secondary'></i>
                    </span>
                  </div>
                </Link>
              </div>
              {/* <div className='d-flex flex-column pe-4 border-end'>
                <span className='text-white-50'>Have any questions?</span>
                <span className='text-secondary'>Call: + 91 00000 00000</span>
              </div> */}
              <div className='d-flex align-items-center justify-content-center ms-4 '>
                {localStorage.getItem('login') ? (
                  <div className='nav-item dropdown bg-danger'>
                    <Link
                      to='#'
                      className='nav-link dropdown-toggle text-light'
                      data-bs-toggle='dropdown'
                    >
                      {localStorage.getItem('name')}{' '}
                      <i className='fa fa-caret-down'></i>
                    </Link>

                    <div className='dropdown-menu fade-up m-0'>
                      {
                        <>
                          <Link to='/dashboard' className='dropdown-item'>
                            {' '}
                            <i className='fa fa-tachometer'></i>Dashboard{' '}
                          </Link>
                        </>
                      }
                      {localStorage.getItem('role') === '1' && (
                        <>
                          <Link
                            to='/dashboard/profile'
                            className='dropdown-item'
                          >
                            <i className='fa fa-users'></i> New item{' '}
                          </Link>
                        </>
                      )}
                      {localStorage.getItem('role') === '2' && (
                        <>
                          <Link to='/dashboard/cart' className='dropdown-item'>
                            {' '}
                            <i className='fa fa-cart-plus'></i>Cart{' '}
                          </Link>
                          <Link
                            to='/dashboard/checkout'
                            className='dropdown-item'
                          >
                            <i className='fa fa-shopping-cart'></i> Checkout
                          </Link>
                        </>
                      )}
                      {
                        <>
                          <Link to='/dashboard/bell' className='dropdown-item'>
                            <i className='fa fa-bell'></i> Alert{' '}
                          </Link>
                          <Link
                            to='/dashboard/notification'
                            className='dropdown-item'
                          >
                            <i className='fa fa-envelope'></i> Notification{' '}
                          </Link>
                          <Link
                            to='/dashboard/profile'
                            className='dropdown-item'
                          >
                            <i className='fa fa-users'></i> Profile{' '}
                          </Link>
                          <Link
                            to='/dashboard/setting'
                            className='dropdown-item'
                          >
                            <i className='fa fa-cog'></i> Settings
                          </Link>
                          <Link
                            to='/dashboard/activity-log'
                            className='dropdown-item'
                          >
                            {' '}
                            <i className='fa fa-sign'></i>Activity Log{' '}
                          </Link>
                        </>
                      }
                      <button className='dropdown-item' onClick={logout}>
                        <i className='fa fa-sign-out-alt'></i>Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <Link to='/login' className='btn btn-primary p-3'>
                      Login{' '}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}
