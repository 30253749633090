import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'

export default function Login() {
  const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState({
        mobile: "",
        password: ""
    });
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();

    const getInputData = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const postData = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${url}/server/login.php`, {
                mobile: data.mobile,
                password: data.password
            });
            const responseData = response.data;
            if (responseData.status) {
                // Store user data and authentication state in local storage
                localStorage.setItem("login", true);
                localStorage.setItem("name", responseData.data.name);
                localStorage.setItem("email", responseData.data.email);
                localStorage.setItem("role", responseData.data.role);
                localStorage.setItem("mobile", responseData.data.mobileNumber);
                // Redirect based on user role
                if (responseData.data.role === 1 || responseData.data.role === 2) {
                    navigate('/dashboard');
                    toast.success(responseData.message);
                }
            } else {
                // Clear any previous authentication state
                localStorage.removeItem("login");
                navigate('/login');
                toast.error(responseData.message);
            }

        } catch (error) {
            setShowError(true);
            toast.error('Error while logging in:', error);
        }
    };

    return (
        <>
        <div className="container-fluid my-3 col-md-6">
            <div className="w-75 m-auto">
                <h5 className='text-center bg-primary p-2 text-light'><span className='text-warning fs-3'>Welcome</span> to Account</h5>
                {showError &&
                    <p className='text-danger text-center p-2'>Invalid Mobile Number or Password</p>
                }
                <form onSubmit={postData}>
                    <div className="mb-3">
                        <label>Mobile Number</label>
                        <input type="text" name="mobile" maxLength={10} minLength={10} onChange={getInputData} placeholder='User Mobile Number' className='form-control' />
                    </div>
                    <div className="mb-3">
                        <label>Password</label>
                        <input type="password" name="password" maxLength={8} minLength={8} onChange={getInputData} placeholder='Password' className='form-control' />
                    </div>
                    <div className="mb-3">
                        <div className="btn-group w-100">
                            <Link to="/signup" className='btn btn-success'>Signup</Link>
                            <Link to="/OTP" className='btn btn-info'>OTP</Link>
                            <button type="submit" className='btn btn-primary'>Login</button>
                        </div>
                        <Link to="#">Forget Password</Link>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}
