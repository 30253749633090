import React from 'react';

export default function Terms() {
  return (
    <>
      <div className="container">
        <h1 className='text-center p-lg-5'>Terms and Conditions</h1>
        <h6>Introduction</h6>
        <span>Welcome to OracalPoint ("Company", "we", "our", "us")! As you have just clicked to our Terms of Service, please pause, grab a cup of coffee, and carefully read the following pages. It will take you approximately 20 minutes.
          These terms and conditions ("Terms", "Agreement") are an agreement between OracalPoint ("Website Operator", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the [yourwebsite.com] website and any of its products or services (collectively, "Website" or "Services").</span>
        <h6>Accounts and Membership</h6>
        <span>If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</span>
        <h6>User Content</h6>
        <span>We do not own any data, information, or material ("Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish, or distribute the Content created by you or stored in your user account for commercial, marketing, or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display, and perform the Content of your user account solely as required for the purpose of providing the Services to you.</span>
        <h6>Billing and Payments</h6>
        <span>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Sensitive and private data exchange between the Website and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.</span>
        <h6>Accuracy of Information</h6>
        <span>Occasionally there may be information on the Website that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, availability, promotions, and offers. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order).</span>
        <h6>Prohibited Uses</h6>
        <span>In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</span>
        <h6>Limitation of Liability</h6>
        <span>To the fullest extent permitted by applicable law, in no event will OracalPoint, its affiliates, officers, directors, employees, agents, suppliers, or licensors be liable to any person for any indirect, incidental, special, punitive, cover, or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages.</span>
        <h6>Indemnification</h6>
        <span>You agree to indemnify and hold OracalPoint and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages, or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services, or any willful misconduct on your part.</span>
        <h6>Governing Law</h6>
        <span>These Terms shall be governed by and construed in accordance with the laws of India, and you submit to the exclusive jurisdiction of the state and federal courts located in india for the resolution of any disputes.</span>
        <h6>Changes and Amendments</h6>
        <span>We reserve the right to modify this Agreement or its</span>
      </div>
    </>
  );
}
