import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Teams from './Teams';
import axios from 'axios';

export default function About() {
  const url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState('');
  async function getData() {
    try {
      const response = await axios.post(`${url}/server/about/index.php`);
      const responseData = response.data;
      if (responseData.status === true) {
        setData(responseData.data);
      } else {
        console.log('Error fetching data');
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (!data) {
    return <h3 className="text-center text-blink">Loading...</h3>;
  }

  const imageData = data.image ? JSON.parse(data.image) : {};
  return (
    <>
      <div className="container-fluid py-3 my-3">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
              <div className="h-100 position-relative">
                {imageData && (
                  <img
                    src={`${url}/server/assets/about/${imageData.image1}`}
                    className="img-fluid w-75 rounded"
                    alt={data.title}
                    style={{ marginBottom: '25%' }}
                  />
                )}
                {imageData && (
                  <div className="position-absolute w-75" style={{ top: '25%', left: '25%' }}>
                    <img
                      src={`${url}/server/assets/about/${imageData.image2}`}
                      className="img-fluid w-75 rounded"
                      alt={data.title}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
              <h5 className="text-primary">{data.title}</h5>
              {/* <h1 className="mb-4">About US</h1> */}
              {/* <p>Hi Shiva</p> */}
              <p className="mb-4">{data.description}</p>
              <Link to={'/'} className="btn btn-secondary rounded-pill px-5 py-3 text-white">
                More Details
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Teams />
    </>
  );
}
