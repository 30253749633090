import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const [message, setMassage] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/society/index.php`);
            const responseData = response.data
            if (responseData.status !== false) {
                setData(responseData.data);
            } else {
                setMassage(responseData.message)
            }
        } catch (error) {
            toast.error('Error fetching data:', error);
        }
    };

    async function mobilecall() {
        try {
            toast.success('Please Wait Calling...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }
    async function whatsappshare() {
        try {
            toast.success('Please Wait ...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }
    async function emailsend() {
        try {
            toast.success('Please Wait ...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }
    async function sharesocialsite() {
        try {
            toast.success('Please Wait ...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Society Visitor List {message}</p>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Email ID</th>
                                        <th>Subject</th>
                                        <th>Massage</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.email}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.massage}</td>
                                            <td className='d-flex'>
                                                <Link onClick={() => mobilecall(item.id)}><i className='btn btn-success fa fa-phone'></i></Link> &nbsp;
                                                <Link onClick={() => whatsappshare(item.id)}><i className="btn btn-info fa fa-comments"aria-hidden="true"></i></Link>&nbsp;
                                                <Link onClick={() => emailsend(item.id)}><i className='btn btn-secondary fa fa-envelope'></i></Link>&nbsp;
                                                <Link onClick={() => sharesocialsite(item.id)} ><i className='btn btn-warning fa fa-share-alt'></i></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}