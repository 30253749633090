import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/realstate/list/`);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    function editRealState() {
        toast.error('Cant Edit This Time', 'Edit RealState')
    }
    async function deleteRealState(emd, itm) {
        try {
            await axios.post(`${url}/realstate/delete/`, {
                realstate_id: emd
            });
            fetchData();
            toast.error(`Real State ${itm} Delete Successfully!!!`);
        } catch (e) {
            toast.error(e.massage);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Real State </p>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Location</th>
                                        <th>Price</th>
                                        <th>Size</th>
                                        <th>Indore Size</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.title}</td>
                                            <td>{item.location}</td>
                                            <td>{item.price}</td>
                                            <td>{item.size}</td>
                                            <td>{item.indoreSize}</td>
                                            <td>
                                                <Link onClick={editRealState} className='btn btn-success'><i className='fa fa-edit'></i></Link> &nbsp;
                                                <Link onClick={() => deleteRealState(item.id, item.title)} className='btn btn-danger'><i className='fa fa-trash-alt'></i></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}