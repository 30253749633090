import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/brand/list/`);
            const responseData = response.data
            if(responseData!==false){
                setData(responseData.data);
            }
            setMessage(responseData.message)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    function editCategory() {
        toast.error('Cant Edit This Time', 'Edit Category')
    }
    async function deleteCategory(emd) {
        try {
            const response = await axios.post(`${url}/server/brand/delete/`, {
                brand_id: emd
            });
            const responseData = response.data
            if (responseData !== false) {
                setMessage(`${emd}`, responseData.message);
                await fetchData();
            }
        } catch (error) {
            console.log('Failed to delete category', error.responseData.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Brand </p>
                            <div className="text-warning py-2">{message}</div>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.title}</td>
                                                <td>
                                                    <Link onClick={editCategory}><i className='btn btn-success fa fa-edit'></i></Link>&nbsp;
                                                    <Link onClick={() => deleteCategory(item.id)}><i className='btn btn-danger fa fa-trash-alt'></i></Link>
                                                </td>
                                            </tr>
                                        ))) :
                                        (
                                            <tr>
                                                <td colSpan="3">
                                                    <h4 className="text-danger text-center">{message}</h4>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}