import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function LoanForm(props) {
  const url = process.env.REACT_APP_BASE_URL;
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    fullname: '',
    mobile1: '',
    mobile2: '',
    email: '',
    loanamount: '',
    loantype: props.lkey
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.fullname || !formData.email || !formData.mobile1) {
      setMessage("Please fill in all required fields.");
      return;
    }
    if (formData.mobile1.length !== 10) {
      setMessage('Mobile number must be 10 digits!');
      return;
    }
    if (formData.loanamount < 10000) {
      setMessage('Minimum Amount must be 10,000 Rs.!');
      return;
    }
    try {
      const response = await axios.post(`${url}/server/leads/create.php`, formData);
      const responseData = response.data;
      setFormData({
        fullname: '',
        mobile1: '',
        mobile2: '',
        email: '',
        loanamount: '',
        loantype: ''
      });
      setMessage(responseData.message);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <>
      <div className="container">
        <div className="Form">
          <form onSubmit={handleSubmit}>
            <div className='mb-3 d-flex'>
              <input type='text' name='fullname' placeholder='Full Name' className='form-control' value={formData.fullname} onChange={handleChange} required />
            </div>
            <div className='mb-3 d-flex'>
              <input type='tel' name='mobile1' maxLength={10} placeholder='Mobile Number' className='form-control' value={formData.mobile1} onChange={handleChange} required />
              <input type='tel' name='mobile2' maxLength={10} placeholder='Alternate Mobile Number' value={formData.mobile2} onChange={handleChange} className='form-control'/>
            </div>
            <div className='mb-3'>
              <input type='email' name='email' placeholder='Email Address' className='form-control' value={formData.email} onChange={handleChange} required/>
            </div>
            <div className='mb-3'>
              <input type='number' name='loanamount' placeholder='Loan Amount' className='form-control' value={formData.loanamount} onChange={handleChange} required />
            </div>
            <div className='mb-3'>
              <input type='text' name='loantype' placeholder='Loan Type' className='form-control' value={formData.loantype} onChange={handleChange} required />
            </div>            
            <p className='text-center text-success'>{message}</p>
            <div className="mb-3 modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
