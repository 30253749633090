import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Components/utility/Loader';
import axios from 'axios';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton,TelegramShareButton, FacebookIcon, TwitterIcon, WhatsappIcon,TelegramIcon } from 'react-share';

export default function ShoppingPoint() {
  const [data, setData] = useState([])
  let url = process.env.REACT_APP_BASE_URL;
  async function getData() {
    try {
      const response = await axios.post(`${url}/server/shoppings/index.php`);
      const responseData = response.data
      if (responseData.status !== false) {
        setData(responseData.data);
      } else {
        console.log(responseData.message);
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Shopping Center</h5>
            <h1>Latest Items</h1>
          </div>
          <div className="row g-5 justify-content-center">
            {
              data.length ? (
                data.map((item, index) => (
                  <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s" key={index}>

                    <div className="blog-item position-relative bg-light rounded mt-5 mb-5">
                      <img src={item.image ? `${url}/server/assets/shoppings/${item.image}` : `${url}/server/assets/noImages.jpeg`} className="img-fluid w-100 rounded-top" alt={item.title} />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{ top: '-28px', right: '20px' }}>{item.title}</span>
                      <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{ marginTop: '-75px' }}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                          <Link to="#/cart" className="btn text-white">Add Cart</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                          <div className="blog-icon-1">
                            <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                          </div>
                          <div className="blog-icon-2">
                            <FacebookShareButton url={url} title={item.title} separator="::" quote={`${item.title}, ${item.brand}, ${item.color}, ${item.size}, ${item.image}`}  hashtag={`${item.hashtag}`} className="btn me-1">
                              <FacebookIcon size={29} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={url} title={item.title} separator="::" quote={`${item.title}, ${item.brand}, ${item.color}, ${item.size}, ${item.image}`} hashtag={`${item.hashtag}`} className="btn me-1">
                              <TwitterIcon size={29} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={url} title={item.title} separator="::" quote={`${item.title}, ${item.brand}, ${item.color}, ${item.size}, ${item.image}`} hashtag={`${item.hashtag}`} className="btn me-1">
                              <WhatsappIcon size={29} round />
                            </WhatsappShareButton>
                            <TelegramShareButton url={url} title={item.title} separator="::" quote={`${item.title}, ${item.brand}, ${item.color}, ${item.size}, ${item.image}`} hashtag={`${item.hashtag}`} className="btn me-1">
                              <TelegramIcon size={29} round />
                            </TelegramShareButton>
                          </div>
                        </div>
                      </div>
                      <div className="blog-content text-left position-relative px-3" style={{ marginTop: '-180px' }}>
                        <h6>Brand : {item.brand}</h6>
                        <h6>Color : {item.color}</h6>
                        <h6>Size : {item.size}</h6>
                        <h6>Price : {item.OriginalRate}</h6>
                      </div>                     
                    </div>
                  </div>
                ))
              ) :
                (
                  <Loader />
                )
            }
          </div>
        </div>
      </div>
    </>
  );
}
