import React from 'react';

export default function Loader() {
  return (
    <>
      <div className="container text-center my-2">
        <p className='spinner-border'></p>
      </div>
    </>
  );
}
