import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const [message, setMassage] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/slider/index.php`);
            const responseData = response.data
            if (responseData.status !== false) {
                setData(responseData.data);
            } else {
                setMassage(responseData.message)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    async function deleteSlider(emd, title) {
        try {
            const response = await axios.post(`${url}/server/slider/delete.php`, {
                slider_id: emd
            });
            const responseData = response.data
            if (responseData.status !== false) {
                toast.success(`${title} ` + responseData.message);
                await fetchData();
            } else {
                toast.error(responseData.message)
            }
        } catch (error) {
            toast.error('Failed to delete Slider', error.responseData.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [data.length]);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Slider {message}</p>
                            <Link to={'/dashboard/Slider/Add'} className='btn btn-success'><i className='fa fa-plus'></i></Link>
                        </div>
                        <div className="card-body">

                            <div className="table-responsive">
                                <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Sub-Title</th>
                                            <th>URL</th>
                                            <th>Image</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data) && data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.sub_title}</td>
                                                <td>{item.url}</td>
                                                <td className='text-center' style={{ padding: 0, margin: 0 }}>
                                                    <img src={`${url}/server/assets/slider/${item.image}`} style={{ width: '30%' }} alt={item.title} className='img-fluid' />
                                                </td>
                                                <td>{item.status}</td>
                                                <td className='d-flex'>
                                                    <Link to={`Edit?id=${item.id}`}><i className='btn btn-success fa fa-edit'></i></Link>&nbsp;
                                                    <Link onClick={() => {
                                                        if (window.confirm('Do you want to delete records?')) {
                                                            deleteSlider(item.id, item.title);
                                                        }
                                                    }}><i className='btn btn-danger fa fa-trash-alt'></i></Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}