import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Sidebar() {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <>
        <ul className="list-group flex-xl-shrink-0">
            <li className="list-group-item list-group-item-action active" aria-current="true">
                <Link to="#" className='text-light'><i className="fa fa-home"></i><span className="float-end">Dashboard</span></Link>
            </li>
            <li className="list-group-item list-group-item-action" onClick={toggleSubMenu}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Link to="#"><i className="fa fa-file"></i><span className="float-end">&nbsp; UI &#10;Pages</span></Link>
                    <i className={`fa ${isSubMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </div>
                {isSubMenuOpen && (
                    <ul className="submenu">
                        <li><Link to="/dashboard/Slider/"><i className="fa fa-user"></i><span className="float-end">Slider</span></Link></li>
                        <li><Link to="/dashboard/"><i className="fa fa-user"></i><span className="float-end">Blog</span></Link></li>
                        <li><Link to="/dashboard/"><i className="fa fa-home"></i><span className="float-end">Project</span></Link></li>
                        <li><Link to="/dashboard/"><i className="fa fa-users"></i><span className="float-end">Testimonial</span></Link></li>
                    </ul>
                )}
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/Page-List"><i className="fa fa-user"></i><span className="float-end">Page's</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/users"><i className="fa fa-users"></i><span className="float-end">Users</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/leads"><i className="fa fa-user"></i><span className="float-end">Leads</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/deals"><i className="fa fa-user"></i><span className="float-end">Deals</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/category"><i className="fa fa-list"></i><span className="float-end">Category</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/brand"><i className="fa fa-list"></i><span className="float-end">Brand</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/product"><i className="fa fa-list"></i><span className="float-end">Product</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/real-state"><i className="fa fa-list"></i><span className="float-end">Real State</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/pan-card"><i className="fa fa-list"></i><span className="float-end">Pan Card</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/Insurances-list"><i className="fa fa-list"></i><span className="float-end">Insurances</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/Loans-List"><i className="fa fa-list"></i><span className="float-end">Loans</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/newsletters"><i className="fa fa-envelope"></i><span className="float-end">Newsletter</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/contact-us"><i className="fa fa-phone"></i><span className="float-end">Contact Us</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/check-out"><i className="fa fa-shopping-cart"></i><span className="float-end">Checkout</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/testimonials"><i className="fa fa-star"></i><span className="float-end">Testimonials</span></Link>
            </li>
            <li className="list-group-item list-group-item-action">
                <Link to="/dashboard/svisitor"><i className="fa fa-phone"></i><span className="float-end">Relesion</span></Link>
            </li>
        </ul>        
        </>
    );
}
