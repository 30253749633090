import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  const url = process.env.REACT_APP_BASE_URL;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [message, setMessage] = useState('');

  const subscribed = async (event) => {
    event.preventDefault();
    try {
      const emailID = document.querySelector("input[name='subscribe']");
      const email = emailID.value;
      const response = await axios.post(`${url}/server/subscriber/create.php`, {
        subscribemail: email,
      });
      const responseData = response.data;
      setMessage(responseData.message);
    } catch (error) {
      console.log(error);
    }
  };
  setTimeout(() => {
    setMessage('');
  }, 3000)
  return (
    <>
      <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <Link to={'/'}>
                <img src={`${url}/server/assets/logo.png`} className="img-fluid rounded-top" width={100} alt={'OracalPoint'} />
              </Link>
              <p className="text-light">Subscribe Here</p>
              <p className='text-warning'>{message}</p>
              <div className='d-flex justify-content-between'>
                <input type="email" name='subscribe' placeholder='Enter Your Email ID' className='rounded' /> <button onClick={subscribed} className='btn btn-primary'>Go</button>
              </div>
              <div className="d-flex hightech-link mt-3">
                <Link to={'/'} className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-facebook-f text-primary"></i></Link>
                <Link to={'/'} className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-twitter text-primary"></i></Link>
                <Link to={'/'} className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-primary"></i></Link>
                <Link to={'/'} className="btn-light nav-fill btn btn-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="#" className="h3 text-secondary">Short Link</Link>
              <div className="mt-4 d-flex flex-column short-link">
                <Link to="/all-insurance" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Insurance</Link>
                <Link to="/loan" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Loan's</Link>
                <Link to="/services-deals" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Services</Link>
                <Link to="/project" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Projects</Link>
                <Link to="/blog" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Latest Blog</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/" className="h3 text-secondary">Help Link</Link>
              <div className="mt-4 d-flex flex-column help-link">
                <Link to="/terms" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Terms Of use</Link>
                <Link to="/privacy-policy" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</Link>
                <Link to="/helps" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Helps</Link>
                <Link to="/faqs" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>FQAs</Link>
                <Link to="/contact-us" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact Us</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/" className="h3 text-secondary">Contact Us</Link>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <Link to="/" className="pb-3 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt text-secondary me-2"></i> 23 IT Park, New Delhi</Link>
                <Link to="tel:+919839201133" className="py-3 text-light border-bottom border-primary"><i className="fas fa-phone-alt text-secondary me-2"></i> +91 98XXXXXXXX</Link>
                <Link to="mailto:info@oracalpoint.com" className="py-3 text-light border-bottom border-primary"><i className="fas fa-envelope text-secondary me-2"></i> info@oracalpoint.com</Link>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <span className="text-light">&copy;<Link to="/"> <i className='text-light'>{currentYear}</i></Link></span>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p>All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div >
      <Link to="#" className="btn btn-secondary btn-square rounded-circle back-to-top"><i className="fa fa-arrow-up text-white"></i></Link>
    </>
  )
}
