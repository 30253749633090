import React from 'react';

const url = process.env.REACT_APP_BASE_URL;

const Form = ({ onSubmit, onChange, formData, mode }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(event); // Call the onSubmit function passed from the parent component
    };

    const handleChange = (event) => {
        // const { name, value } = event.target;
        onChange(event); // Call the onChange function passed from the parent component
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete='off'>
                        <div className="form-group">
                        <label htmlFor="title">Title:</label>
                            <input className="form-control" type="text" name="title" value={formData.title} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Rate of interest(p.a):</label>
                            <input className="form-control" type="text"  name="interestratePA" value={formData.interestratePA} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Rate of interest(p.m):</label>
                            <input className="form-control" type="text"  name="interestratePM" value={formData.interestratePM} onChange={handleChange} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="image">Image:</label>
                            <input type="file" name="image" className='form-control' onChange={handleChange}placeholder="Image"/>
                            {formData.image && (
                                <img src={`${url}/server/${formData.image}`} style={{ width: '30%' }} alt={formData.title} className="img-fluid" />
                            )}
                        </div>
                        
                        <div className='my-2'>
                            {mode === 'add' && <button className="btn btn-primary" type="submit">Add</button>}
                            {mode === 'edit' && <button className="btn btn-primary" type="submit">Edit</button>}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Form;
