import React from 'react';
import About from './About';
import Services from './Deals';
import Project from './Project';
import Blog from './Blog';
import Testimonials from './Testimonials';
import FactStart from './FactStart';
import Carousel from './Carousel';

const Index = () => {
  return (
    <>
      <Carousel/>
      <FactStart />
      <About />
      <Services />
      <Project />
      <Blog />
      <Testimonials />
    </>
  );
};

export default Index;
