import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const [message, setMessage] = useState();
    // for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex)
    const npage = Math.ceil(data.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/contact/`);
            const responseData = response.data
            if (responseData.status !== false) {
                setData(responseData.data);
            } else {
                setMessage(responseData.message)
            }
        } catch (error) {
            toast.error('Error fetching data:', error);
        }
    };

    async function mobileCall() {
        try {
            toast.success('Please Wait Calling...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }

    async function whatsappShare() {
        try {
            toast.success('Please Wait ...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }

    async function emailSend() {
        try {
            toast.success('Please Wait ...')
        } catch (e) {
            toast.error('Cant Call This Time', e)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Contact List {message}</p>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Email ID</th>
                                        <th>Subject</th>
                                        <th>Message</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records && records.map((item, index) => (
                                        <tr key={index}>
                                            <td>{firstIndex + index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.email}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.message}</td>
                                            <td className='d-flex'>
                                                <Link onClick={() => mobileCall(item.id)}><i className='btn btn-success fa fa-phone'></i></Link> &nbsp;
                                                <Link onClick={() => whatsappShare(item.id)}><i className="btn btn-info fa fa-comments" aria-hidden="true"></i></Link>&nbsp;
                                                <Link onClick={() => emailSend(item.id)}><i className='btn btn-secondary fa fa-envelope'></i></Link>&nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <nav>
                                <ul className='pagination'>
                                    {numbers.map((n, i) => (
                                        <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                                            <a href='#' className='page-link' onClick={() => setCurrentPage(n)}>{n}</a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
