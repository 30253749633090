import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Components/utility/Loader';

export default function Carousel() {
    const url = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState([])
    async function getData() {
        try {
            const response = await axios.post(`${url}/server/slider/index.php`);
            const responseData = response.data;
            if(responseData.status!==false){
                setData(responseData.data);
            }else{
                console.log('Slider Image Not Found!!!')
            }
        } catch (e) {
            console.log(e.message);
        }
    }
    useEffect(() => {
        getData()
    }, [data.length])
    return (
        <>
            <div className="container-fluid px-0">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    {data.length ? (
                        <>
                            <ol className="carousel-indicators">
                                <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true" aria-label="First slide"></li>
                                <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
                            </ol>
                            <div className="carousel-inner" role="listbox">
                                {data && data.map((item, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                        <img src={item.image ? `${url}/server/assets/slider/${item.image}` : ''} className="img-fluid" alt={item.title} />
                                        <div className="carousel-caption">
                                            <div className="container carousel-content">
                                                <h6 className="text-secondary h4 animated fadeInUp">{item.title}</h6>
                                                <h1 className="text-white display-1 mb-4 animated fadeInRight">{item.sub_title}</h1>
                                                <p className="mb-4 text-white fs-5 animated fadeInDown">{item.description}</p>
                                                <Link to={item.url} className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></Link>
                                                <Link to="/contact-us" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </>
    );
}
