import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/testimonial/list/`);
            const responseData = response.data
            if (responseData !== false) {
                setData(responseData.data);
            }
            setMessage(responseData.message)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    function editCategory() {
        toast.error('Cant Edit This Time', 'Edit Category')
    }
    async function deleteCategory(emd, itm) {
        try {
            await axios.post(`${url}/server/testimonial/delete`, {
                testimonial_id: emd
            });
            fetchData()
            toast.success(`Testimonial ${itm} Delete Successfully!!!!`)
        } catch (e) {
            console.log(`Testimonial ${itm} Failed To Delete !!!`)
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Testimonial</p>
                            <div className="text-warning py-2">{message}</div>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Profession</th>
                                        <th>star</th>
                                        <th>comment</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ? (
                                            data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.profession}</td>
                                                    <td>{item.star}</td>
                                                    <td>{item.comment}</td>
                                                    <td><img src={item.image} alt={item.title} /></td>
                                                    <td className='d-flex my-1'>
                                                        <Link onClick={() => editCategory(item.id)} className='btn btn-success'><i className='fa fa-edit'></i></Link>
                                                        <Link onClick={() => deleteCategory(item.id, item.title)} className='btn btn-danger'><i className='fa fa-trash-alt'></i></Link>
                                                    </td>
                                                </tr>
                                            ))) :
                                            (
                                                <tr>
                                                    <td className='text-center text-danger'>{message}</td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}