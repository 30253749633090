import React, { useState } from 'react';
import Sidebar from '../SideBar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Form from './Form';
import { useNavigate } from 'react-router-dom';

export default function Add() {
    const url = process.env.REACT_APP_BASE_URL;

    const [formData, setFormData] = useState({
        title: '',
        interestratePA: '',
        interestratePM: '',
        image: '',
    });
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: files && files.length > 0 ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${url}/server/loans/create.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const responseData = response.data;
            if (responseData.status !== false) {
                navigate('/dashboard/Loans-List');
                toast.success(responseData.message);
            } else {
                setMessage(responseData.message);
                toast.error(responseData.message);
            }
        } catch (error) {
            console.error('Something went wrong!', error);
            toast.error('Something went wrong! Please try again later.');
        }
    };

    return (
        <div className="container-fluid my-3">
            <div className="row">
                <div className="col-md-3">
                    <Sidebar />
                </div>
                <div className="col-md-9">
                    <div className='bg-primary d-flex justify-content-between'>
                        <p className='m-2 text-light'>Back</p>
                        <p className='my-1 text-warning'>{message}</p>
                        <Link to={'/dashboard/Loans-List'} className='btn btn-success'><i className='fa fa-arrow-up'></i></Link>
                    </div>
                    <Form onSubmit={handleSubmit} onChange={handleChange} formData={formData} mode="add" />
                </div>
            </div>
        </div>
    );
}
