import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Sidebar from '../SideBar';

export default function Edit() {
    const url = process.env.REACT_APP_BASE_URL;

    const [category, setCategory] = useState('');
    const [message, setMassage] = useState();
    const navigate = useNavigate();
    const { id } = useParams();
    console.log(id)

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    async function getCategory() {
        try {
            const response = await axios.post(`${url}/server/category/view.php`, {
                category_id: id
            });
            const responseData = response.data;
            if (responseData.status !== false) {
                setCategory(responseData.data)
            } else {
                setMassage(responseData.message);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${url}/server/category/edit.php`, {
                title: category,
                category_id: id,
            });
            const responseData = response.data
            if (responseData.status === true) {
                toast.success(responseData.message);
                navigate('/dashboard/Category');
            } else {
                setMassage(responseData.message);
            }
        } catch (error) {
            console.error('Error submitting category:', error);
        }
    };

    var headingStyle = {
        textAlign: "center",
        marginTop: "4px",
        borderRadius: "10px",
        color: "white"
    };
    useEffect(() => {
        getCategory();
    });
    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-primary d-flex justify-content-between'>
                            <p style={headingStyle}>Update Category {message}</p>
                            <Link to="/dashboard/category" className='btn btn-success'><i className='text-light'>Back</i></Link>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="category" className='form-group my-3'>Title</label>
                                {
                                    category && (
                                        <>
                                            <input type="text" id="category" value={category.title} onChange={handleCategoryChange} className="form-control" placeholder='Enter Category' />
                                        </>
                                    )
                                }
                                <button type="submit" className="btn btn-primary my-3 float-end">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
