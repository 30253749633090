import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;

    const [data, setData] = useState([]);
    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/category/index.php`);
            const responseData = response.data
            if (responseData.status !== false) {
                setData(responseData.data);
            } else {
                toast.error(responseData.message)
            }
        } catch (error) {
            toast.error('Error fetching data:', error);
        }
    };

    async function deleteCategory(emd, itm) {
        try {
            if (window.confirm(`Are you sure you want to delete category ${itm} ?`)) {
                const response = await axios.post(`${url}/server/category/delete.php`, {
                    category_id: emd
                });
                const responseData = response.data
                if (responseData.status === true) {
                    fetchData();
                    toast.success(`${itm} - `, responseData.message);
                } else {
                    toast.error(responseData.message)
                }
            }
        } catch (error) {
            toast.error('Can\'t Delete This Time', error);
        }
    }


    useEffect(() => {
        fetchData();
    }, [data.length]);

    let emptyData = {
        "text-align": "center",
        "margin-top": "5px",
    };

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Category</p>
                            <Link to="add-categroy" className='btn btn-success'><i className='fa fa-plus text-light'></i></Link>
                        </div>
                        <div className="table table-responsive">
                            {
                                data.length ?
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(data) && data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        <Link to={`edit-category/${item.id}`}><i className='btn btn-success fa fa-edit'></i></Link>&nbsp;
                                                        <Link onClick={() => {
                                                            if (window.confirm('Do you want to delete records?')) {
                                                                deleteCategory(item.id, item.title);
                                                            }
                                                        }}><i className='btn btn-danger fa fa-trash-alt'></i></Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    : <h1 style={emptyData}>Data Not Found !!!</h1>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}