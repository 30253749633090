import React, { useState } from 'react';

const PlantProduct = () => {
  const url = process.env.REACT_APP_BASE_URL;

    const [formData, setFormData] = useState({
        plant_id: '1',
        section_id: '1',
        subsection_id: '1',
        equipment_id: '1',
        category_id: '1',
        subcategory_id: '1',
        description: '1',
        equipment: '1',
        version: '1',
        checksheettype: '1',
        create_by: '1',
        checkpoints: [],
        spareParts: [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCheckpointChange = (index, field, value) => {
        const updatedCheckpoints = [...formData.checkpoints];
        updatedCheckpoints[index] = {
            ...updatedCheckpoints[index],
            [field]: value,
        };
        setFormData((prevData) => ({ ...prevData, checkpoints: updatedCheckpoints }));
    };

    const handleSparePartChange = (index, field, value) => {
        const updatedSpareParts = [...formData.spareParts];
        updatedSpareParts[index] = {
            ...updatedSpareParts[index],
            [field]: value,
        };
        setFormData((prevData) => ({ ...prevData, spareParts: updatedSpareParts }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${url}/server/productObject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                // Reset the form or perform any other actions
            } else {
                const data = await response.json();
                console.error('Form submission failed:', data.message);
            }
        } catch (error) {
            console.error('Error during form submission:', error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Render your form fields here, for example: */}
            <input type="text" name="plant_id" value={formData.plant_id} onChange={handleChange} placeholder="Plant ID"/>
            <input type="text" name="section_id" value={formData.section_id} onChange={handleChange} placeholder="section_id"/>
            <input type="text" name="subsection_id" value={formData.subsection_id} onChange={handleChange} placeholder="subsection_id"/>
            <input type="text" name="equipment_id" value={formData.equipment_id} onChange={handleChange} placeholder="equipment_id"/>
            <input type="text" name="category_id" value={formData.category_id} onChange={handleChange} placeholder="category_id"/>
            <input type="text" name="subcategory_id" value={formData.subcategory_id} onChange={handleChange} placeholder="subcategory_id"/>
            <input type="text" name="description" value={formData.description} onChange={handleChange} placeholder="description"/>
            <input type="text" name="equipment" value={formData.equipment} onChange={handleChange} placeholder="equipment"/>
            <input type="text" name="version" value={formData.version} onChange={handleChange} placeholder="version"/>
            <input type="text" name="checksheettype" value={formData.checksheettype} onChange={handleChange} placeholder="checksheettype"/>
            <input type="text" name="create_by" value={formData.create_by} onChange={handleChange} placeholder="create_by"/>

            {/* Render checkpoints and spare parts dynamically */}
            {formData.checkpoints.map((checkpoint, index) => (
                <div key={index}>
                    <input
                        type="text"
                        name={`checkpoints[${index}].title`}
                        value={checkpoint.title || ''}
                        onChange={(e) => handleCheckpointChange(index, 'title', e.target.value)}
                        placeholder="Checkpoint Title"
                    />
                    <input
                        type="text"
                        name={`checkpoints[${index}].std_value`}
                        value={checkpoint.std_value || ''}
                        onChange={(e) => handleCheckpointChange(index, 'std_value', e.target.value)}
                        placeholder="Standard Value"
                    />
                    <input
                        type="text"
                        name={`checkpoints[${index}].check_method`}
                        value={checkpoint.check_method || ''}
                        onChange={(e) => handleCheckpointChange(index, 'check_method', e.target.value)}
                        placeholder="Check Method"
                    />
                    <input
                        type="text"
                        name={`checkpoints[${index}].actual_value`}
                        value={checkpoint.actual_value || ''}
                        onChange={(e) => handleCheckpointChange(index, 'actual_value', e.target.value)}
                        placeholder="Actual Value"
                    />
                    <input
                        type="text"
                        name={`checkpoints[${index}].remark`}
                        value={checkpoint.remark || ''}
                        onChange={(e) => handleCheckpointChange(index, 'remark', e.target.value)}
                        placeholder="Remark"
                    />
                    <input
                        type="file"
                        name={`checkpoints[${index}].media`}
                        onChange={(e) => handleCheckpointChange(index, 'media', e.target.files[0])}
                        accept="image/*"
                    />
                    {/* Add other checkpoint fields similarly */}
                </div>
            ))}


            {formData.spareParts.map((sparePart, index) => (
                <div key={index}>
                    <input
                        type="text"
                        name={`spareParts[${index}].spare_part`}
                        value={sparePart.spare_part || ''}
                        onChange={(e) => handleSparePartChange(index, 'spare_part', e.target.value)}
                        placeholder="Spare Part"
                    />
                    <input
                        type="text"
                        name={`spareParts[${index}].descriotion`}
                        value={sparePart.descriotion || ''}
                        onChange={(e) => handleSparePartChange(index, 'descriotion', e.target.value)}
                        placeholder="Spare Part"
                    />
                    <input
                        type="text"
                        name={`spareParts[${index}].qty`}
                        value={sparePart.qty || ''}
                        onChange={(e) => handleSparePartChange(index, 'qty', e.target.value)}
                        placeholder="Spare Part"
                    />
                    <input
                        type="text"
                        name={`spareParts[${index}].reason`}
                        value={sparePart.reason || ''}
                        onChange={(e) => handleSparePartChange(index, 'reason', e.target.value)}
                        placeholder="Spare Part"
                    />
                    <input
                        type="text"
                        name={`spareParts[${index}].remark`}
                        value={sparePart.remark || ''}
                        onChange={(e) => handleSparePartChange(index, 'remark', e.target.value)}
                        placeholder="Spare Part"
                    />
                    <input
                        type="text"
                        name={`spareParts[${index}].media`}
                        value={sparePart.media || ''}
                        onChange={(e) => handleSparePartChange(index, 'media', e.target.value)}
                        placeholder="Spare Part"
                    />
                </div>
            ))}

            <button type="submit">Submit</button>
        </form>
    );
};

export default PlantProduct;
