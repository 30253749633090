import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../SideBar';

export default function Add() {
  const url = process.env.REACT_APP_BASE_URL;
  const [category, setCategory] = useState('');
  const [message, setMassage] = useState('');
  const navigate = useNavigate()
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/server/category/create.php`, {
        title: category
      });
      const responseData = response.data;
      if (responseData.status === true) {
        navigate('/dashboard/Category/');
        toast.success(responseData.message);
      } else {
        setMassage(responseData.message);
      }
    } catch (error) {
      setMassage('Error submitting category:', error);
    }
  };

  var headingStyle = {
    textAlign: "center",
    marginTop: "4px", // Added "px" unit
    borderRadius: "10px",
    color: "white"
  };

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className='col-md-9'>
            <div className='bg-primary d-flex justify-content-between'>
              <p style={headingStyle} className='my-1 p-1'>Add New Category <b className='text-light bg-danger py-1 m-2 rounded-2'>{message ? message : ''}</b></p>
              <Link to="/dashboard/category" className='btn btn-success'><i className='text-light'>Back</i></Link>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label htmlFor="category" className='form-group my-3'>Title</label>
                <input type="text" id="category" value={category} onChange={handleCategoryChange} className="form-control" placeholder='Enter Category' />
                <button type="submit" className="btn btn-primary my-3 float-end">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
