import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Form from './Form'; // Import Form component

export default function Edit() {
    const url = process.env.REACT_APP_BASE_URL;

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const slider_id = params.get('id');
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        title: '',
        sub_title: '',
        url: '',
        image: null,
        status: '',
        description: ''
    });

    async function getData() {
        try {
            const response = await axios.post(`${url}/server/slider/view.php`, {
                slider_id: slider_id
            });
            const responseData = response.data;
            setFormData(responseData.data);
        } catch (error) {
            toast.error('Failed to fetch Slider.');
        }
    }

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files && files.length > 0) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: files[0]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        formDataToSend.append('slider_id', slider_id); 
        try {
            const response = await axios.post(`${url}/server/slider/edit.php`, formDataToSend);
            const responseData = response.data;
            if (responseData.status !== false) {
                setMessage(responseData.message);
                toast.success(responseData.message);
            } else {
                setMessage(responseData.message);
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Failed to update Slider.');
        }
    };

    useEffect(() => {
        getData();
    });

    if (!formData) return null;

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Back</p>
                            <b>{message}</b>
                            <Link to={'/dashboard/slider'} className='btn btn-success'><i className='fa fa-arrow-up'></i></Link>
                        </div>
                        <Form onSubmit={handleSubmit} onChange={handleChange} formData={formData} mode="edit" />
                    </div>
                </div>
            </div>
        </>
    );
}
