import React, { useEffect, useState } from 'react'
import Loader from '../Components/utility/Loader'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

export default function Carrier() {
  const [carrear, setCarrier] = useState(0);
  const url = process.env.REACT_APP_BASE_URL
  const currentUrl=window.location.href
  async function getCarrier() {
    try {
      const response = await axios.post(`${url}/server/carrier/index.php`)
      const responseData = response.data
      if (responseData.status !== false) {
        setCarrier(responseData.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCarrier();
  }, [carrear.length])
  return (
    <>
      <div className="container-fluid blog py-5 mb-5">
  <div className="container">
    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
      <h5 className="text-primary">Carrier Section</h5>
      <h1>Latest Carrier & News</h1>
    </div>
    <div className="row g-5 justify-content-center">
      {
        carrear.length ? (
          carrear.map((item, index) => (
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s" key={index}>
              <div className="blog-item position-relative bg-light rounded">
                <img 
                  src={item.image ? `${url}/server/assets/carrier/${item.image}` : `${url}/server/assets/noImages.jpeg`} 
                  className="img-fluid w-100 rounded-top" 
                  alt={item.title.replace(' ','')} 
                />
                <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{ top: '-28px', right: '20px' }}>
                  {item.title}
                </span>
                <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{ marginTop: '-75px' }}>
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="#" className="btn text-white">Apply</Link>
                  </div>
                  <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                    <div className="blog-icon-1">
                      <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                    </div>
                    <div className="blog-icon-2">
                      <FacebookShareButton url={currentUrl} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                        <FacebookIcon size={29} round />
                      </FacebookShareButton>
                      <TwitterShareButton url={currentUrl} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                        <TwitterIcon size={29} round />
                      </TwitterShareButton>
                      <WhatsappShareButton url={currentUrl} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                        <WhatsappIcon size={29} round />
                      </WhatsappShareButton>
                      <TelegramShareButton url={currentUrl} title={item.title} quote={item.title} hashtag={item.hashtag} className="btn me-1">
                        <TelegramIcon size={29} round />
                      </TelegramShareButton>
                    </div>
                  </div>
                </div>
                <div className="blog-content text-center position-relative px-3" style={{ marginTop: '20px' }}>
                  <p className="py-2">{item.description}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )
      }
    </div>
  </div>
</div>

    </>
  )
}
