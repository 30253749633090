import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from './SideBar';

export default function Index() {
    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar/>
                    </div>
                    <div className="col-md-9">
                        <h5 className='bg-primary text-light p-2 text-center'>Dashboard</h5>
                        <div className="row">
                            <div className="col-md-6">
                                <img src="assets/noImages.jpeg" alt="" height="325px" width="100%" />
                            </div>
                            <div className="col-md-6">
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr>
                                            <th>UserName</th>
                                            <td><input type='text' className='form-control' disabled value={localStorage.getItem("name")}/></td>
                                        </tr>
                                        <tr>
                                            <th>Role</th>
                                            <td><input type='text' className='form-control'disabled value={localStorage.getItem("role")}/></td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td><input type='text' className='form-control' disabled value={localStorage.getItem("email")}/></td>
                                        </tr>
                                        <tr>
                                            <th>Phone</th>
                                            <td><input type='text' className='form-control'disabled value={localStorage.getItem("mobile")}/></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><Link to="/update-profile" className='btn btn-primary w-100'>Update Profile</Link></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}
