import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loader from '../Components/utility/Loader'

export default function FactStart () {
  const url = process.env.REACT_APP_BASE_URL
  const [data, setData] = useState([])
  async function getData () {
    try {
      const response = await axios.post(`${url}/server/happycustomer/index.php`)
      const responseData = response.data
      if (responseData !== false) {
        setData(responseData.data)
      } else {
        console.log(responseData.message)
      }
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    getData()
  }, [data.length])
  return (
    <>
      {data.length ? (
        <div className='container-fluid bg-warning py-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 wow fadeIn' data-wow-delay='.1s'>
                <div className='d-flex counter'>
                  <h1 className='me-3 text-primary counter-value'>{data[0].totalCustomer}</h1>
                  <h5 className='text-white mt-1'>
                    Success in getting happy customer
                  </h5>
                </div>
              </div>
              <div className='col-lg-3 wow fadeIn' data-wow-delay='.3s'>
                <div className='d-flex counter'>
                  <h1 className='me-3 text-primary counter-value'>{data[0].business}</h1>
                  <h5 className='text-white mt-1'>
                    Member of successful business
                  </h5>
                </div>
              </div>
              <div className='col-lg-3 wow fadeIn' data-wow-delay='.5s'>
                <div className='d-flex counter'>
                  <h1 className='me-3 text-primary counter-value'>{data[0].totalCustomer}</h1>
                  <h5 className='text-white mt-1'>
                    Total clients who love OracalPoint
                  </h5>
                </div>
              </div>
              <div className='col-lg-3 wow fadeIn' data-wow-delay='.7s'>
                <div className='d-flex counter'>
                  <h1 className='me-3 text-primary counter-value'>{data[0].Star5}</h1>
                  <h5 className='text-white mt-1'>
                   5* Stars review given by satisfied clients
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <Loader />
      )}
    </>
  )
}
