import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/pancard/index.php`);
            const responseData = response.data;
            // console.log(responseData.data);
            if (responseData !== false) {
                setData(responseData.data);
            }
            setMessage(responseData.message)

        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    function editCategory() {
        toast.error('Cant Edit This Time', 'Edit Category')
    }
    async function deleteCategory(emd) {
        try {
            await axios.post(`${url}/brand/delete/`, {
                brand_id: emd
            });
            toast.warning(`Category ${emd} deleted successfully`);
            await fetchData();
        } catch (error) {
            toast.error('Failed to delete category', error.response.data.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [data.length]);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>Insurance</p>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Refered By</th>
                                        <th>Status Mode</th>
                                        <th>Pan Number</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th colSpan={3}>Image</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ? (
                                            data.map((item, index) => {
                                                const name = item.name ? JSON.parse(item.name) : {};
                                                const phoneNumbers = item.phone ? JSON.parse(item.phone) : [];
                                                const photo = item.photo ? JSON.parse(item.photo) : {};
                                                console.log(photo.signature);
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.referral_by}</td>
                                                        <td>{item.pan_mode}</td>
                                                        <td>{item.panNumber ? item.panNumber : 'N/A'}</td>
                                                        <td>{name.fname} {name.mname} {name.lname}</td>
                                                        <td>
                                                            {phoneNumbers.map((phone, idx) => ( 
                                                                <a key={idx} href={`tel:${phone}`} className="btn border-0 rounded p-1"> {phone}</a>
                                                            ))} 
                                                        </td>
                                                        <td><a href={`mailto:${item.mailId}`} className='btn btn-info bg-info'>{item.mailId}</a></td>
                                                        <td>{item.LiveAddress}</td>
                                                        <td colSpan={3} className='d-flex'>
                                                            <img src={`${url}/assets/pancard/${photo.applicant}`} alt={name.fname+'_Applicant-Name'} width={100} className='p-1'/>
                                                            <img src={`${url}/assets/pancard/${photo.aadharCard}`} alt={name.fname+'_Aadhar-card'} width={100}className='p-1'/>
                                                            <img src={`${url}/assets/pancard/${photo.signature}`} alt={name.fname+'_Signature'} width={100}className='p-1'/>
                                                        </td>
                                                        <td>{item.status}</td>
                                                        <td className='d-flex'>
                                                            <Link onClick={() => editCategory(item)}><i className="btn btn-success fa fa-edit"></i></Link>&nbsp;
                                                            <Link onClick={() => deleteCategory(item.id)}><i className="btn btn-danger fa fa-trash-alt"></i></Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="9">
                                                    <h4 className="text-center">{message}</h4>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}