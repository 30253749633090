import axios from 'axios'
import React, { useState } from 'react'

export default function Pancard () {
  const url = process.env.REACT_APP_BASE_URL
  const [message, setMessage] = useState('')
  const initialState = {
    panType: '',
    fname: '',
    mname: '',
    lname: '',
    mobile1: '',
    mobile2: '',
    email: '',
    appphoto:'',
    aadharcard: '',
    signature: '',
    address: ''
  }
  const [formData, setFormData] = useState(initialState)

  const handleChange = e => {
    const { name, value, files } = e.target
    setFormData({ ...formData, [name]: value })
    if (files && files.length > 0) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: files[0]
      }))
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }))
    }
  }

  async function handleSubmit (event) {
    event.preventDefault()
    const formDataToSend = new FormData()

    for (const key in formData) {
      formDataToSend.append(key, formData[key])
    }
    try {
      const response = await axios.post(`${url}/server/pancard/create.php`,formDataToSend)
      const responseData = response.data
      if (responseData.status) {
        setFormData(initialState)
        setMessage(responseData.message)
      } else {
        setMessage(responseData.message)
      }
    } catch (e) {
      console.log(e)
      setMessage('An error occurred.')
    }
  }

  return (
    <>
      <div className='container'>
        <h1 className='text-center'>Pan Card</h1>
        <h5 className='text-center'>Get 5min Online Pan-card</h5>
        <div className='row'>
          <div className='col-md-5 wow fadeIn' data-wow-delay='.3s'>
            <div className='p-5 h-100 rounded pan-card img-fluid'>
              <img src={`${url}/server/assets/deal/pancard.jpeg`} alt='pan-card' />
            </div>
          </div>
          <div className='col-md-7'>
            <form
              onSubmit={handleSubmit}
              encType='multipart/form-data'
              method='POST'
            >
              <div className='form-check mb-3 d-flex'>
                <div className='form-check'>
                  <label
                    className='form-check-label me-2 btn btn-primary'
                    htmlFor='newPan'
                  >
                    New Pan Card
                  </label>
                  <input
                    type='radio'
                    name='panType'
                    id='newPan'
                    className='form-check-input p-2'
                    value='New Pan Card'
                    onChange={handleChange}
                  />
                </div>
                <div className='form-check'>
                  <label
                    className='form-check-label me-2 btn btn-primary'
                    htmlFor='updatePan'
                  >
                    Update Pan Card
                  </label>
                  <input
                    type='radio'
                    name='panType'
                    id='updatePan'
                    className='form-check-input p-2'
                    value='Update Pan Card'
                    onChange={handleChange}
                  />
                </div>
                <div className='form-check'>
                  <label
                    className='form-check-label me-2 btn btn-primary'
                    htmlFor='businessPan'
                  >
                    Business Pan Card
                  </label>
                  <input
                    type='radio'
                    name='panType'
                    id='businessPan'
                    className='form-check-input p-2'
                    value='Business Pan Card'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='mb-3 d-flex'>
                <input
                  type='text'
                  name='fname'
                  placeholder='First Name'
                  className='form-control'
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
                <input
                  type='text'
                  name='mname'
                  placeholder='Middle Name'
                  value={formData.mname}
                  onChange={handleChange}
                  className='form-control'
                />
                <input
                  type='text'
                  name='lname'
                  placeholder='Last Name'
                  className='form-control'
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3 d-flex'>
                <input
                  type='tel'
                  name='mobile1'
                  maxLength={10}
                  minLength={10}
                  placeholder='Mobile Number'
                  className='form-control'
                  value={formData.mobile1}
                  onChange={handleChange}
                  required
                />
                <input
                  type='tel'
                  name='mobile2'
                  maxLength={10}
                  minLength={10}
                  placeholder='Alternate Mobile Number'
                  value={formData.mobile2}
                  onChange={handleChange}
                  className='form-control'
                />
              </div>
              <div className='mb-3'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  className='form-control'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3 d-flex'>
                <input
                  type='file'
                  name='appphoto'
                  className='form-control'
                  onChange={handleChange}
                  required
                  multiple
                />
                <span
                  className='input-group-text bg-dark text-light'
                  id='basic-addon1'
                >
                  Appicant Photo
                </span>
              </div>
              <div className='mb-3 d-flex'>
                <input
                  type='file'
                  name='aadharcard'
                  className='form-control'
                  onChange={handleChange}
                  required
                  multiple
                />
                <span
                  className='input-group-text bg-dark text-light'
                  id='basic-addon1'
                >
                  Aadhar Card
                </span>
              </div>
              <div className='mb-3 d-flex'>
                <input
                  type='file'
                  name='signature'
                  className='form-control'
                  onChange={handleChange}
                  required
                />
                <span
                  className='input-group-text bg-dark text-light'
                  id='basic-addon1'
                >
                  Signature
                </span>
              </div>
              <div className='mb-3'>
                <textarea
                  name='address'
                  rows='2'
                  className='form-control'
                  placeholder='Current Address with city and Landmarks...'
                  value={formData.address}
                  onChange={handleChange}
                ></textarea>
              </div>
              <p className='text-center text-success'>{message}</p>
              <div className='mb-3'>
                <button type='submit' className='btn btn-primary btn-sm w-100'>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
