import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Index() {
    const url = process.env.REACT_APP_BASE_URL;
    const [message,setMessage]=useState('');
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${url}/server/subscriber/index.php`);
            const responseData = response.data
            if(responseData!==false){
                setData(responseData.data);
            }
            setMessage(responseData.message)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    async function sendnewslatter(emd) {
        try {
            toast.success('Send News Latter Successfully!!!')
        } catch (e) {
            toast.error('Cant This Time Send Any Massage !!!', e)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className='bg-primary d-flex justify-content-between'>
                            <p className='m-2 text-light'>News Latter</p>
                            <button type="button" data-toggle="modal" data-target="#addCategoryModal" className='btn btn-success'><i className='fa fa-plus'></i></button>
                        </div>
                        <div className="table">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.subscribe_email}</td>
                                                <td>{item.status}</td>
                                                <td>
                                                    <Link onClick={() => sendnewslatter(item.id)} className='btn btn-info'><i className='fa fa-paper-plane'></i></Link>
                                                </td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td colSpan="9">
                                                <h4 className="text-center">{message}</h4>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}