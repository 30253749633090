import React, { useState, useEffect } from 'react'
import Sidebar from '../SideBar'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Index () {
  const url = process.env.REACT_APP_BASE_URL
  const [message, setMassage] = useState();

  const [data, setData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.post(`${url}/server/shoppings/index.php`)
      const responseData = response.data
      if(responseData!==false){
          setData(responseData.data)
      }else{
        setMassage(responseData.message);
      }
    } catch (error) {
      console.log('Error fetching data:', error)
    }
  }

  const editProduct = () => {
    toast.error("Can't Edit This Time", 'Edit Product')
  }

  async function deleteProduct (emd, item) {
    try {
      await axios.post(`${url}/server/shoppings/delete/`, {
        product_id: emd
      })
      setMassage(`Product ${item} deleted successfully`)
      await fetchData()
    } catch (e) {
      toast.error("Can't Delete This Time", e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [data.length])

  return (
    <>
<div className='container-fluid my-3'>
  <div className='row'>
    <div className='col-md-3'>
      <Sidebar />
    </div>
    <div className='col-md-9'>
      <div className='bg-primary d-flex justify-content-between'>
        <p className='m-2 text-light'>Shopping Product <d className='text-warning ms-5'>{message}</d></p>
        <button
          type='button'
          data-toggle='modal'
          data-target='#addCategoryModal'
          className='btn btn-success'
        >
          <i className='fa fa-plus'></i>
        </button>
      </div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Brand</th>
              <th>Color</th>
              <th>Size</th>
              <th>Rate</th>
              <th>Copon</th>
              <th>Available Stock</th>
              <th>Description</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.category}</td>
                  <td>{item.subcategory}</td>
                  <td>{item.brand}</td>
                  <td>{item.color}</td>
                  <td>{item.size}</td>
                  <td>{item.OriginalRate}</td>
                  <td>{item.coponNumber}</td>
                  <td>{item.stock}</td>
                  <td>{item.description}</td>
                  <td> <img src={ `${url}/` +
                        (item.Image
                          ? item.Image
                          : '/assets/noimages.jpeg')
                      }
                      alt={item.title}
                      style={{ width: 100, height: 100 }}
                    />
                  </td>
                  <td> <Link onClick={() => editProduct(item.id)} className='btn btn-success' ><i className='fa fa-edit'></i>
                    </Link>
                    &nbsp;
                    <Link   onClick={() => deleteProduct(item.id, item.title)} className='btn btn-danger' >
                      <i className='fa fa-trash-alt'></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='13'>
                  <h4 className='text-center'>Data Not Found !!!</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

    </>
  )
}
