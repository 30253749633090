import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Signup() {
    let url = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState({
        name: "",
        username: "",
        email: "",
        phone: "",
        password: "",
        cpassword: ""
    });
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const getInputData = (e) => {
        const { name, value } = e.target;
        setData((old) => ({
            ...old,
            [name]: value
        }));
    };
function sendOtp(){
    alert('Sorry this time not send OTP')
}
    const postData = async (e) => {
        e.preventDefault();
        if (data.password === data.cpassword) {
            try {
                let response = await axios.post(`${url}/server/user/create.php`, data);
                response = response.data;
                if (response.status) {
                    setShow(true);
                    toast.success(`Hi ${data.username} Welcome ${response.message}`)
                    setMessage(`Hi ${data.username}, registration successful!`);
                    setData([]);
                } else {
                    navigate("/signup");
                    setShow(true);
                    // toast.error(`Sorry ${data.username} Pls Check ${response.message}`)
                    setMessage(`Sorry ${data.username} Pls Check ${response.message}`);
                }
            } catch (error) {
                setShow(true);
                setMessage(error.response.data.message || "An error occurred during registration.");
            }
        } else {
            setShow(true);
            setMessage("Password and Confirm Password don't match!");
        }
    };

    return (
        <>
            <div className="container-fluid my-3 col-md-6">
                <div className="w-75 m-auto">
                    <h5 className='text-center bg-primary p-2 text-light'><span className='text-warning fs-3'>Login</span> Your Account</h5>
                    {show &&
                        <p className='text-danger text-center p-2'>{message}</p>
                    }
                    <form onSubmit={postData}>

                        <div className="row">
                            <div className="col-md-12 mb-3 d-flex justify-content-between">
                                <input type="email" name="email" onChange={getInputData} placeholder='Email Address' className='form-control' />
                                <Link to="#" onClick={sendOtp} className='btn btn-info ps-1'>Send OTP</Link>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label>OTP</label>
                                <input type="text" name="otp" onChange={getInputData} placeholder='Enter OTP here' className='form-control' />
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="btn-group w-100">
                                <Link to="/login" className='btn btn-success'>Login</Link>
                                <Link to="/OTP" className='btn btn-info'>OTP</Link>
                                <button type="submit" className='btn btn-primary'>Signup</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
